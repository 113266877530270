.footerContainer {
  z-index: 2;
  background-image: url("../../assets/img/bg_footer.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 200px;
  position: relative;

  @media screen and (min-width:1225px){
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-repeat: repeat;
    padding-bottom: 260px;
    padding-top: 160px;
  }
}
.footerSections {
  margin-bottom: 20px;
  font-family: "Barlow";
  font-size: 1.1em;
  letter-spacing: 1.84px;
  cursor: pointer;
  color: #fff;
  text-align: center;

  @media screen and (min-width:1225px){
    text-align: start;
    margin-bottom: 10px;
    font-weight: 500;
  }
}
.footerSectionsBar {
  height: 5px;
  width: 70px;
  background-color: white;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 2.5em;

  @media screen and (min-width: 1225px){
    margin-left:0;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.footerLogoContainer {
  display: flex !important;
  justify-content: center;
  align-items: center;

  & > img {
    width: 60%;
    max-width: 350px;
    @media (min-width: 600px) {
      max-width: 200px;
      width: 45%;
    }
    @media (min-width: 900px) {
      max-width: 200px;
      width: 35%;
    }
    @media (min-width: 1225px){
      width: 45%;
      max-width: 300px
    }
  }
}

.footerFarolInfo {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  font-family: "Barlow";
  color: #fff;
  & > h1 {
    margin-bottom: 10px;
    letter-spacing: 2.5px;
    font-size: 1.2em;
  }

  & > p {
    text-align: center;
    font-style: italic;
    margin-bottom: 40px;
    font-size: 0.9em;
  }

  & > h2 {
    letter-spacing: 2px;
    font-size: 1.05em;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  & > .footerIconContainer {
    margin-top: 50px;

    & > .footerBottomIcon {
      margin-left: 7.5px;
      margin-right: 7.5px;
      & > path {
        stroke-width: 1;
        fill: white !important;
      }
    }
    @media screen and (min-width:1225px) {
      display: none;
    }
  }
  
  @media screen and (min-width:1225px) {
    &>h2, >p, >h1{
      text-align: end;
      width: 100%;
    }
    &>h2, >h1{
      font-weight: bold;
    }
    margin-right: 5vw !important;
  }
}

.sagueeFooterMark {
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-end;
  & > div {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 2;
    & > h1 {
      font-size: 1.4em;
      font-family: "Barlow";
      font-weight: normal;
      margin-right: 10px;
      margin-top: 0;
    }
  }
  & > p {
    width: 100%;
    text-align: center;
    font-style: italic;
    color: #484848;
    margin-bottom: 10px;
    font-size: 0.85em;
    z-index: 2;
  }
  @media screen and (min-width:1225px){
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    & > div{
      width: auto;
      margin: 0;
      align-items: flex-end;
      margin-right: 6vw;
      &>h1{
        margin-bottom: 10px;
      }
    }
    & > p {
      width: auto;
      margin: 0;
      line-height: initial;
      margin-left: 6vw;
      display: flex;
      align-items: flex-end;
    }
  }

}
.sagueeFooterLogoContainer{
  & > img {
    width: 90px;
    height: auto;
  }
  
  @media screen and (min-width:1225px){
    width: 100%;
  }
}
.sagueeFooterWavebox {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  & > img {
    width: 90px;
    height: auto;
  }
  
  @media screen and (min-width:1225px){
    width: 120%;
    display: block;
    margin-left: -10%;
  }
}

.footerCtnerLeft{
  @media screen and (min-width:1225px){
    margin-left: 5vw !important;
  }
}