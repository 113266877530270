.root {
    flex-grow: 1;
    position: relative;
    z-index: 2
}
.menuButton {
    position: absolute;
    left: 90%;
    justify-self: flex-end;
    // margin-right: theme.spacing(2);
}
.logoContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo {
    height: 70px;
    width: auto;
}
.toolbar {
    min-height: 45;
    align-items: center;
    // padding-top: theme.spacing(1);
    // padding-bottom: theme.spacing(2);
    box-shadow: 0px 3px 6px #00000014;
}
.drawer {
    width: 80vw;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right bottom;
}
.drawerOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 16px;
}
.closeDrawer {
    margin-top: 35px;
    padding-right: 16px;
    font-family: Barlow;
    letter-spacing: 3px;
    color: #7C7C7C;
    cursor: pointer;
}
.drawerItem {
    margin-bottom: 20px;
    font-family: Barlow;
    font-size: 1em;
    letter-spacing: 1.84px;
    cursor: pointer;
    color: #000;
}
.drawerBottom {
    margin-bottom: 35px;
    padding-right: 16px;
}
.drawerBottomItem {
    margin-right: 10px;
}