@media (min-width: 1224px) {
    .container {
        margin: 0 !important;
        margin-top: -96px !important;
        max-width: 100% !important;
        padding-right: 0 !important;
        padding-left: 0 !important;

        .root {
            height: 100vh;

            .tittle {
                .bannerLogo {
                    width: 45%;
                }
                .subTitle {
                    margin-left: 0;
                    font-size: 24px;
                }
            }

            .leftWaveContainer {
                .wave {
                    left: -45px;
                    top: 80px;
                }
            }

            .rightWaveContainer {
                .wave {
                    right: -45px;
                }
            }

            .rightWaveContainer, .leftWaveContainer {
                .wave {
                    & > g > path{
                        fill: transparent !important;
                        stroke-width: 14px;
                    }
                }
            }

            .leftBall {
                height: 400px;
                width: 400px;
            }

            .rightBall {
                height: 380px;
                width: 400px;
            }
        }
    }

    .baseInfoBanner {
        margin-top: -20px;
        box-shadow: 0 0 6px #00000012;
        z-index: 5;
        position: relative;
        flex-direction: row !important;
        display: flex !important;
        padding: 50px !important;
        padding-left: 75px !important;
        padding-right: 75px !important;
        width: 85% !important;
        border-radius: 8px;
        justify-content: space-evenly;
        background-color: #FFFFFF;

        p {
            text-align: left !important;
            margin-left: 0 !important;
        }
        
        .infoBannerContainer {
            align-items: flex-start !important;
            justify-content: space-between;
            width: 60%;
            padding: 0;
            height: 100%;
        }
        .bannerImage {
            width: 40%;
            max-height: 100%;
        }

        .callToBookingWrap {
            margin: 0;
            .callToBooking {
                width: 300px;
                bottom: 0;
                border-radius: 50px;
            }
        }
    }
}
@media (min-width: 1224px) {
    .structureAndServices__container{
        background-color: transparent;
    }
}