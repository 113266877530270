.root {
    margin-right: -16px;
    margin-left: -16px;
    position: relative;
    background-image: url('../../assets/img/bannerBackDesktop.png');
    background-position: bottom;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-evenly;
    height: 88vh;

    .overlay {
        position:absolute;
        background-color: #00AAB2;
        overflow: hidden;
        width: 100%;
        height: 100%;
        opacity: 0.35;
    }
    
    .leftBall {
        position: fixed;
        top: 60px;
        left: -150px;
        border-radius: 100%;
        opacity: 0.2 ;
        height: 300px;
        width: 300px;
        background-color: #01A9AF;
    }
    
    .rightBall {
        position: fixed;
        bottom: -100px;
        right: -190px;
        border-radius: 100%;
        opacity: 0.35;
        height: 280px;
        width: 300px;
        background-color: #FACB26;
    }
    
    .leftWaveContainer {
        position: relative;
        width: 100%;
        .wave {
            position: relative;
            left: -65px;
            & > g > path{
                fill: transparent !important;
                stroke-width: 10px;
            }
        }
    }

    .rightWaveContainer {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        .wave {
            position: relative;
            right: -65px;
    
            & > g > path{
                fill: transparent !important;
                stroke-width: 10px;
            }
        }
    }
    
    .callToScroll {
        display: flex;
        position: absolute;
        bottom: 50px;
        align-items: center;
        flex-direction: column;
        width: 100%;
        
        .callToScrollText {
            font-family: Raleway;
            margin-top: 7px;
            color: #FFF;
        }
        & > svg > g > g > rect{
            fill: transparent !important;
            stroke-width: 6px;
        }
    }
    
    .tittle {
        align-items: center;
        display: flex;
        flex-direction: column;
        bottom: 220px;
        align-items: center;
        
        .subTitle {
            margin-left:30px;
            color: #FFF;
            font-size: 28px;
            font-family: Raleway;
            position: relative;
            
            .subTitle2 {
                padding-left: 25px;
            }
        }

        .bannerLogo {
            position: relative;
            width: 95%;
        }
    }
}
.callToBookingWrap {
    justify-content: center;
    z-index: 2;
    display: flex;
    position: relative;
    margin-right: -16px;
    margin-left: -16px;
    background: #FFF;

    .callToBooking {
        z-index: 3;
        position: relative;
        bottom: 25px;
        font-family: Barlow;
        font-weight: 400;
        text-transform: uppercase;
        color: #FFF;
        height: 50px;
        width: 80vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-image: url('../../assets/img/callToBookingButton.png');
        background-position: right;
    }
}