.carouselWrapper{
  margin: auto;
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 30px;

  &>.carouselMenuBackBar{
    background-color: rgba(27, 27, 27, .14);
    height: 4px;
    width: 100%;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
   
    @media screen and (min-width:1225px){
      width: 80%;
    }
  }
}
.carouselMenuFrontBar{
  height: 4px;
  background-color: rgba(1, 169, 175, 1);
  transition: width 1s ease;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
 }

.carouselMenu{
  display: flex;
  justify-content: center;

  &>p{
    font-size: 50px;
    margin: 10px;
    color:#7C7C7C;
    cursor: pointer;
  }
}

.verticalCarousel{
  display: flex;
  flex-direction: column;
}

.horizontalCarousel{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

.reduceWidthBar{
  width: 60%;
}