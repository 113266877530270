.socialMediaCard{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  z-index: 1000;
  background-color: white;
  right: 0;
  top: 50vh;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.iconSocialMediaCard {
  width: 25px;
  cursor: pointer;
  height: 25px;
  margin: 10px 0px 10px;
  & > svg {
    width: 25px;
  }
}

.iconSocialMediaCard > path {
  stroke-width: 1;
  fill: #D3D3D3;
}

.iconSocialMediaCard:hover > path {
  fill: #01A9AF;
}

