
.cardDepoimentoCarousel{
  background-color: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0px;
  padding-top: 5px;
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  
  &>h1{
    font-family: 'Barlow';
    font-size: 1.3em;
    font-weight: normal;
    color: rgba(124, 124, 124, 1);
    width: 85%;
    margin: 0;
    margin-bottom: 10px;
    line-height: 1.2em;
    &:after, &:before{
      font-weight: bold;
      color: rgba(124, 124, 124, 1);
      margin: 0;
      width: 90%;
      font-family: 'Barlow';
      font-size: 1.4em  ;
    }
    &:before{
      content:'"';
      position: relative;
      right: 5px;
    }
    &:after{
      content:'  "';
      position: relative;
      top: 15px;
      left: 5px;
    }
  }

  &>p{
    width: 90%;
    margin-bottom: 15px;
  }
}


.cardDepoimentoNome, .cardDepoimentoFonte{
  color: rgba(1, 169, 175, 1);
  font-family: 'Barlow';
  font-size: 1.15em;
  text-align: center;
  margin: 5px;
}


.depoAddTransition{
  transition: 1s right ease;
  position: relative;

}

.depoInitiateTransition{
  right: 500px;
}


.cardDepoimentoMLA{
  margin-left: auto;
  margin-right: 30px;
}
.cardDepoimentoMRA{
  margin-right: auto;
  margin-left: 30px;
}
