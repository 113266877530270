.header__menuIcon {
  width: 20px;

  & > path {
    stroke-width: 1;
  }
}

.header__drawerBottomItem {
  margin-right: 10px;
}
.header__drawerBottomItem path {
  fill: white;
  stroke-width: 1;
}
