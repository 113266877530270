body {
  margin: 0;
  padding-top: 94px;
}

#root{
  
  background-color: #F7F7F7;
}


.mouse-point {
  animation: 1.5s ease 0s scroll infinite;

  @keyframes scroll {
      0% {
          fill: white;
          transform: translate(95.056px, 1586.36px);
      }
      65% {
          fill: rgba(255, 255, 255, 0.31);
      }
      100% {
          fill: transparent;
          transform: translate(95.056px, 1626.36px);
      }
  }
}