.drawerContainerDesktop {
  width: 30vw;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left top;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: "Barlow";
}

.drawerContainer {
  width: 100vw;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left top;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: "Barlow";
}

.topContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  & > img {
    margin-top: 15px;
    width: 100%;
  }
  & > svg {
    top: -5px;
    position: relative;
    width: 50%;
    height: auto;
  }
  padding-bottom: 20px;
}

form {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
textarea,
.customized-select {
  font-family: Barlow;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  outline: 0;
  font-size: 18px;
  color: rgba(75, 75, 75, 0.8);
  border-width: 0 0 1px;
  border-color: rgba(75, 75, 75, 0.8);
}

.customized-select {
  width: 100%;
  text-align: center;
  border-style: solid;
  border-width: 0 0 1px;
  color: rgba(75, 75, 75, 0.8);
  border-color: rgba(75, 75, 75, 0.8);
}

input::placeholder,
textarea::placeholder {
  text-align: center;
}

.submitButton {
  color: white;
  font-family: "Barlow";
  font-size: 14px;
  margin-top: 20px;
  height: 40px;
  width: 70%;
  letter-spacing: 1.5px;
  background: #01a9af 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border-color: transparent;
}

.iconBottom {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 30px;
  & > svg {
    width: 25px;
  }
  & > path {
    stroke-width: 1;
  }
}

.select {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 18px;
  color: #4b4b4b;
  border-width: 0 0 2px;
  border-color: #4b4b4b;
}

.closeDrawer {
  color: white;
  margin-top: 20px;
  letter-spacing: 3px;
  cursor: pointer;
}

.MuiSelect-select.MuiSelect-select{
  padding-right: 0 !important;
  color: rgba(75, 75, 75, 0.8) !important;
}