.errorContainer {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  padding-top: 0px;
  min-height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.errorImageContainer {
  position: relative;
  width: 40%;
  top: 10vh;
  & > img {
    width: 100%;
    height: auto;
  }

  & > .firstChild {
    width: 80%;
    position: relative;
    top: 20px;
  }

  & > .secondChild {
    position: relative;
    top: -70px;
  }
}

.errorTitleContainer {
  align-items: center;
  width: 70%;
  flex: 1;
  display: flex;
  flex-direction: column;
  & > img {
    width: 100%;
    height: auto;
  }
}

.errorRightSide {
  position: absolute;
  height: 100%;
  right: 0;
  width: 80px;
  & > img {
    position: absolute;
    width: 80%;
    right: 0;
    top: 10vh;
    height: auto;
  }
  & > div {
    position: absolute;
    width: 100%;
    padding-top: 100%;
    bottom: 0;
    border-top-left-radius: 100%;
    background: #facb26 0% 0% no-repeat padding-box;
    opacity: 0.5;
  }
}

.errorLeftSide {
  position: absolute;
  width: 60px;
  left: 0;
  margin-top: 150px;
  & > img {
    top: 90vh;
    width: 100%;
    height: auto;
  }
}

.errorButton {
  color: white;
  font-family: "Barlow";
  font-size: 14px;
  margin-top: 10px;
  height: 40px;
  width: 70%;
  letter-spacing: 1.5px;
  background: #01a9af 0% 0% no-repeat padding-box;
  border-radius: 25px;
}
