.cardStructureCarousel {
    width: 85vw;
    height: 340px;
    background-size: cover;
    position: relative;
    
    .overlay {
        background: linear-gradient(180deg, rgba(2,0,36,0.03125) 0%, rgba(1,169,175,0) 44%, rgba(1,169,175,0.65) 100%);
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
    }

    .cardDisplay {
        color: white;
        position: absolute;
        left: 20px;
        bottom: 40px;
        display: flex;
        flex-direction: column;
        font-family: Barlow;
        max-width: 75%;
        
        .cardTitle {
            font-style: italic;
            font-weight: 700;
            font-size: 22px;
        }

        .cardSubtitle {
            font-style: 17px;
        }
    }
}

@media (min-width: 1224px) {
    .cardStructureCarousel {
        width: 25vw;
    }
}